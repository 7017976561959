<template>
  <router-link
    test="product-card"
    class="uk-link-reset uk-card-default uk-box-shadow-medium uk-box-shadow-hover-small uk-border-rounded overflow-hidden flex flex-col items-center"
    :to="'/store/' + product.id"
  >
    <div class="uk-card-media-top">
      <div class="uk-inline bg-white" style="height: 300px; width: 300px">
        <div class="uk-text-center h-auto w-full"
        >
          <img
              style=""
              :src="
              product.main_image
                ? product.main_image.url
                : api + '/storefronts/' + storefrontId + '/logo'
            "
          />
       </div>

        <div
          v-if="product.feature_weight != 0"
          class="uk-position-top-right rounded-l-full text-sm shadow-lx flex items-center justify-center px-2 py-1"
          :style="storefrontBackground"
        >
          <span uk-icon="heart"></span>
          <span class="uk-margin-small-left">Destacado</span>
        </div>

        <div
          v-if="showQuickview && storefrontStorefront.type === 'b2b'"
          class="uk-position-bottom-right uk-margin-small-right uk-margin-small-bottom"
        >
          <button
            @click="setActive(product)"
            href="#quickview-modal"
            uk-toggle
            class="uk-button uk-button-secondary uk-border-rounded"
          >
            <div uk-icon="icon: expand; ratio: 0.7;"></div>
          </button>
        </div>
      </div>
    </div>
    <div
      class=""
      :title="
        storefrontStorefront.type === 'redeem' &&
        product.storefront_redeemables.length &&
        product.storefront_redeemables[0].display_name
          ? product.storefront_redeemables[0].display_name
          : product.display_name +
            ' - ' +
            (['administrator'].includes(authUser.type.slug)
              ? product.name + ' - '
              : '') +
            product.display_code
      "
    >
      <div
        v-if="
          storefrontStorefront.type === 'redeem' &&
          product.storefront_redeemables.length &&
          product.storefront_redeemables[0].display_name
        "
        class="flex flex-col items-center justify-center w-full px-4 my-4"
      >
        <div
          class="text-sm mb-2 break-words text-center font-bold text-gray-500"
        >
          {{ product.storefront_redeemables[0].display_name }}
        </div>

        <div
          class="uk-flex uk-flex-middle uk-flex-right w-24 bg-gray-700 rounded-full text-white px-2 py-1"
          test="product-card-price"
        >
          <div class="text-sm font-bold">
            {{
              formatThousands(product.storefront_redeemables[0].current_price)
            }}
          </div>
          <div v-if="pointsIcon">
            <img :src="pointsIcon" alt="" width="30px" />
          </div>
        </div>
      </div>
      <div v-else class="uk-flex uk-flex-center">
        <div>{{ product.display_name }}</div>
        <div v-if="['administrator'].includes(authUser.type.slug)">
          - {{ product.name }}
        </div>
        <div class="uk-margin-small-left uk-text-muted">
          {{ product.display_code }}
        </div>
      </div>
    </div>
    <div v-if="product.startingAtQuantity">
      <div>
        Desde
        {{ product.startingAtQuantity }}
        unidades
      </div>
      <div>
        por
        {{ toCurrency(product.startingAtPrice) }}
        &#8454;
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductCard",

  props: ["product", "setActive", "showQuickview"],

  computed: {
    ...mapGetters([
      "storefrontStorefront",
      "storefrontBackground",
      "authUser",
      "pointsName",
      "pointsIcon",
    ]),
  },
};
</script>
